@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap');

body{
    @apply
    bg-gray-600 /*TODO Remove*/
    p-3
}

.textInputsShort{
    @apply
    w-40
    rounded
    pl-2
    outline
    outline-2
    outline-slate-800
    block
    mt-4
}
.textInputsShort:last-child{
    @apply
    mb-2
}

.basicContainer{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @apply
    px-4
    py-6
    mb-3
    bg-white
    shadow-md
    rounded
    outline
    outline-1
}

.overlay{
    position: absolute;
    max-width: 400px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 40%;
    z-index: 999;
    background-color: #fff;
}

.listQuery{
}

input{
    @apply
    rounded
    pl-1
    w-20
}

.pageTitle{
    @apply
    text-2xl
    drop-shadow-sm
    font-semibold
    /*TODO Change font family*/
}

.pageTitle{
}

.exerciseInputsContainer, .exerciseOptionsContainer, .basicSplitFlexContainer{
    @apply
    flex
    justify-evenly
}

.homePageSplitFlex{
    @apply
    flex
    justify-between
}

.sessionDetailsContainer{
    @apply
    px-6
    flex
    justify-between
}

.leftSideOfExerciseInputs{

}

.rightSideOfExerciseInputs{

}

.addAndSubtractButtons{

}

.subtractButton{
    @apply
    mr-8
}

.addButton{
    @apply
    m-4
}

/*
.toastContainer{
    @apply
    text-white
}

.toastLightTheme{
    @apply
    text-white
}
*/

.exerciseNumberInput, .exerciseNumberSelector{
    @apply
    mx-2
}

.totalExerciseNumberSelector{
    @apply
    mr-2
}

.selectOrAddExercise{
    @apply
    w-40
}

.selectOrAddExerciseSelector{

}

.selectOrAddExerciseInput{

}

.selectOrAddExerciseFieldChangeButton{
    @apply
    ml-4
}

.exerciseHeading{
    display: block;
    text-align: center;

    @apply
    pb-2
    text-lg
    drop-shadow-sm
    font-semibold
}

.exerciseInputsTitles{
    text-align: center;
    display: block;
}

.setOptionContainer{
    text-align: center;
}

.headerContainer{
    @apply
    flex
    justify-between
    px-4
    py-4
}

h2, .pageTitle{
    font-family: 'Libre Franklin', sans-serif;
}

h2{
    text-align: center;

    @apply
    pb-2
    text-lg
    drop-shadow-sm
    font-semibold
    block
    text-yellow-900;
}


.tableOfSession {
    border-collapse: collapse;
    width: 100%;
}

.tableSessionRow {
    border-bottom: 1pt solid black;
}

.tableSessionDate{
    font-weight: 500;
    filter: drop-shadow(0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.2));
}

.tableSessionHeader{
}

.centerAndFullWidth{
    width: 100%;
}

.fullWidth{
    display: block;
}

.smallerSelect{
    width: 60px;
}

h2{
    margin-bottom: 8px;
}

.selectorTitle{
    font-weight: 500;
    display: block;
}

.genericBottomMargin{
    @apply
    mb-6
}

.genericBottomMarginLarge{
    @apply
    mb-10
}

.defaultsSelectorTitle{
    font-weight: 500;
    @apply
    mb-10
}

.defaultsSelectorsFlexbox{
    @apply
    flex
    justify-evenly
}

.center{
    width: 100%;
    text-align: center;
    margin: auto;
    display: block;
}

.tripleWidthFlex{
    @apply
    flex
    mb-6
}

.threeEqualWidth{
    width: 33.3%
}

.threeEqualWidth:last-child{
    text-align: end;
}

.selectPreviousSessionButton{
    @apply
    mt-20
    block
}

.selectPreviousSessionButtonContainer{
    @apply
    mt-4
}

.exerciseContainerOptions{
    @apply
    mt-4
    flex
    justify-around

}

.setsFinishedCounter{
    text-align: center;
}

.setsFinishedText{
    font-family: monospace;
    @apply
    mx-3
    font-semibold
    text-lg
}